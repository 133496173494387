import _forOwn from 'lodash.forown';
import _isArray from 'lodash.isarray';
import _isObject from 'lodash.isobject';
import { systemProperties } from 'utils/constants';

// eslint-disable-next-line import/prefer-default-export
export const deepTraverseObject = async (obj, options = {}, path = '', rootkey = '') => {
    await _forOwn(obj, async (val, key) => {
        // eslint-disable-next-line no-param-reassign
        // to handle particular keys in object
        if (
            ((_isArray(options.matchKey) && options.matchKey.indexOf(key) !== -1) ||
                options.matchKey === key ||
                (options.matchKeyRegex && options.matchKeyRegex.test(key))) &&
            typeof options.matchCB === 'function'
        ) {
            await options.matchCB(obj, key, path, rootkey);
        }
        // to handle particular values in object
        else if (
            ((_isArray(options.matchValue) && options.matchValue.indexOf(val) !== -1) ||
                options.matchValue === val ||
                (options.matchValueRegex && options.matchValueRegex.test(val))) &&
            typeof options.matchCB === 'function'
        ) {
            await options.matchCB(obj, key, path, rootkey);
        } else if (_isArray(val)) {
            // to handle arrays
            const finalPath = `${path ? `${path}.` : ''}${key}`;

            if (options.matchValueType === 'array' && typeof options.matchCB === 'function') {
                await options.matchCB(obj, key, finalPath, rootkey);
            } else {
                val.forEach(async (el, idx) => {
                    await deepTraverseObject(el, options, `${finalPath}[${idx}]`, key);
                });
            }
        } else if (_isObject(val)) {
            // to handle object
            const finalPath = `${path ? `${path}.` : ''}${key}`;

            if (options.matchValueType === 'object' && typeof options.matchCB === 'function') {
                await options.matchCB(obj, key, finalPath, rootkey);
            } else {
                await deepTraverseObject(val, options, finalPath, key);
            }
        } else {
            // do something with leaf node
        }
    });
};

const fieldsPermissionCheck = (accessArr, params) => {
    if (accessArr.includes('rootAdmin') && !params.isRootAdmin) {
        return false;
    }
    if (accessArr.includes('clientAdmin') && !params.isClientAdmin) {
        return false;
    }
    return true;
};
export const getUISchema = (schema = {}, params = {}, isParentArray = false, parentKey = '') => {
    const UISchema = {};
    const keys = Object.keys(schema);
    keys.forEach((key) => {
        if (key === 'j_overrides') {
            UISchema.j_overrides = {
                'ui:field': 'j_override_widget'
            };
        } else if (
            schema[key] &&
            schema[key].type === 'array' &&
            schema[key].items &&
            schema[key].items.type === 'object' &&
            schema[key].items.properties
        ) {
            const { properties } = schema[key].items;

            UISchema[key] = {
                items: getUISchema(properties, {}, true, `${parentKey ? `${parentKey}.` : ''}${key}`)
            };
        } else if (
            schema[key] &&
            schema[key].j_type &&
            typeof schema[key].j_type === 'object' &&
            Array.isArray(schema[key].j_type.access)
        ) {
            const { properties } = schema[key];
            if (!fieldsPermissionCheck(schema[key].j_type.access, params)) {
                UISchema[key] = UISchema[key] || {};
                UISchema[key]['ui:disabled'] = 'disabled';
            }
            if (properties) {
                UISchema[key] = { ...UISchema[key], ...getUISchema(properties) };
            }
        } else if (key === 'j_ref') {
            UISchema.j_ref = {
                'ui:field': isParentArray ? 'j_array_ref_widget' : 'j_ref_widget',
                path: parentKey
            };
        } else if (schema[key] && schema[key].type === 'boolean' && key !== 'isActive') {
            UISchema[key] = {
                'ui:field': 'j_boolean_widget'
            };
        } else if (schema[key] && schema[key].j_type && schema[key].j_type === 'html') {
            UISchema[key] = {
                'ui:field': 'j_html_widget'
            };
        } else {
            const { properties } = schema[key];
            UISchema[key] = getUISchema(properties, {}, false, `${parentKey ? `${parentKey}.` : ''}${key}`);
        }
    });
    return UISchema;
};

export const getClientDisplayName = (clientKey, clients) => {
    if (
        clients[clientKey] &&
        clients[clientKey].details &&
        clients[clientKey].details[systemProperties.J_META] &&
        clients[clientKey].details[systemProperties.J_META].displayName
    ) {
        return clients[clientKey].details[systemProperties.J_META].displayName;
    }
    return clientKey.replace('_', ' ');
};

export const isRootAndClientAdmin = (userData, client) => {
    const clientData = userData.clients[client];
    if (clientData && clientData.admin && userData.admin) {
        return true;
    }
    return false;
};

export const isRootOrClientAdmin = (userData, client) => {
    const clientData = userData.clients[client];
    if ((clientData && clientData.admin) || userData.admin) {
        return true;
    }
    return false;
};
export const isRootAdmin = (userData) => userData.admin;

export const isClientAdmin = (userData, client) => {
    const clientData = userData.clients[client];
    if (clientData && clientData.admin) {
        return true;
    }
    return false;
};
export const hasDeletePermission = (userData, client, type) => {
    if (isRootAndClientAdmin(userData, client)) {
        return true;
    }
    const { clients: { [client]: currentClient = {} } = {} } = userData || {};
    const { permissions: { types: { delete: allowedTypes = [] } = {} } = {} } = currentClient;
    return allowedTypes.includes(type) || allowedTypes.includes('all');
};
export const hasBulkCreatePermission = (userData, client, type) => {
    if (isRootAndClientAdmin(userData, client)) {
        return true;
    }
    const { clients: { [client]: currentClient = {} } = {} } = userData || {};
    const { permissions: { types: { bulkCreate: allowedTypes = [] } = {} } = {} } = currentClient;
    return allowedTypes.includes(type) || allowedTypes.includes('all');
};

export const deleteCookie = async (key) => {
    document.cookie = `${key}=;expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
};

export const parseVersionList = (updatedBy, updatedAt) =>
    `${updatedBy ? `${updatedBy}:` : ''}${new Date(updatedAt).toLocaleDateString()}:${new Date(updatedAt).toLocaleTimeString()}`;

export const truncateString = (str, num) => (str.length > num ? `${str.slice(0, num)}...` : str);

const findPropertyNameByDataType = (jsonSchema = {}, dataType) => {
    const propertyNames = [];
    deepTraverseObject(jsonSchema, {
        matchKey: 'j_type',
        matchCB: (obj, key, path, rootkey) => {
            if (obj[key] === dataType) {
                propertyNames.push(rootkey);
            }
        }
    });
    return propertyNames;
};

export const deepUpdateByDataType = (data, jsonSchema, dataType, cb) => {
    const availableTypes = findPropertyNameByDataType(jsonSchema, dataType);
    if (availableTypes.length) {
        availableTypes.forEach((propertyName) => {
            deepTraverseObject(data, {
                matchKey: propertyName,
                matchCB: (obj, key, path) => {
                    const finalPath = path ? `${path}.${key}` : key;
                    const valueAtKey = obj[key] || '';
                    cb(data, finalPath, valueAtKey);
                }
            });
        });
    }
};
export const pruneSchema = (schema, json) => {
    if (schema.type === 'object' && schema.properties) {
        const prunedProperties = {};

        const keys = Object.keys(schema.properties);
        keys.forEach((key) => {
            if (Object.prototype.hasOwnProperty.call(json, key)) {
                prunedProperties[key] = pruneSchema(schema.properties[key], json[key]);
            }
        });

        return {
            ...schema,
            properties: prunedProperties
        };
    }
    if (schema.type === 'array' && Array.isArray(json)) {
        if (json.length > 0) {
            return {
                ...schema,
                items: pruneSchema(schema.items, json[0])
            };
        }
        return schema;
    }
    return schema;
};
